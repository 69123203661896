.tip-container {
    display: flex;
    flex-direction: column-reverse;
    word-break: break-all;
}

.tip-container img {
    margin-bottom: 40px;
    object-fit: cover;
    width: 80%;
    height: auto;
}

.tip-container p {
    margin-top: 0;
}

.img-container {
    display: flex;
    justify-content: center;
}

.desktop_img {
    display: block;
    display: flex;
    justify-content: center;
}

.mobile_img {
    display: none;
}

@media only screen and (max-width: 768px) {
    .desktop_img {
        display: none;
    }

    .mobile_img {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 428px) {
    .tip-container img {
        width: 100%;
    }
}